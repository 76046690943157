
/**
 * Created by wangtong on 2022/2/7
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {createGroupBattleShareData, createGroupCampShareData, GroupBattleShareData, GroupCampShareData} from "@/models";
import {GroupApi} from "@/api/group";
import ShareCard from "@/components/ShareCard.vue";
import BasePage from "@/mixins/BasePage";
import MobileAppDownloader from "@/components/app_downloader/MobileAppDownloader.vue";
import PcAppDownloader from "@/components/app_downloader/PcAppDownloader.vue";
import GroupCampShareCard from "@/components/share/GroupCampShareCard.vue";

@Component({
    components: {GroupCampShareCard, PcAppDownloader, MobileAppDownloader, ShareCard}
})
export default class GroupCampSharePage extends BasePage {
    @Prop() id!: any

    shareData: GroupCampShareData = createGroupCampShareData()

    async created() {
        super.created()
        this.shareData = await GroupApi.getCampShareData({share_id: this.id})
    }

    get appSchema(): string {
        return `sandan:///app/group/camp/chat?groupId=${this.shareData.record.group_id}`
    }

}
